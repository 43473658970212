
var Rive = (() => {
  var _scriptDir = typeof document !== 'undefined' && document.currentScript ? document.currentScript.src : undefined;
  
  return (
function(Rive) {
  Rive = Rive || {};


null;var m;m||(m=typeof Rive !== 'undefined' ? Rive : {});var aa,ba;m.ready=new Promise(function(b,a){aa=b;ba=a});
function ca(){function b(h){const g=d;c=a=0;d=new Map;g.forEach(l=>{try{l(h)}catch(k){console.error(k)}});this.La();e&&e.fb()}let a=0,c=0,d=new Map,e=null,f=null;this.requestAnimationFrame=function(h){a||(a=requestAnimationFrame(b.bind(this)));const g=++c;d.set(g,h);return g};this.cancelAnimationFrame=function(h){d.delete(h);a&&0==d.size&&(cancelAnimationFrame(a),a=0)};this.cb=function(h){f&&(document.body.remove(f),f=null);h||(f=document.createElement("div"),f.style.backgroundColor="black",f.style.position=
"fixed",f.style.right=0,f.style.top=0,f.style.color="white",f.style.padding="4px",f.innerHTML="RIVE FPS",h=function(g){f.innerHTML="RIVE FPS "+g.toFixed(1)},document.body.appendChild(f));e=new function(){let g=0,l=0;this.fb=function(){var k=performance.now();l?(++g,k-=l,1E3<k&&(h(1E3*g/k),g=l=0)):(l=k,g=0)}}};this.$a=function(){f&&(document.body.remove(f),f=null);e=null};this.La=function(){}}
function ea(b){console.assert(!0);const a=new Map;let c=-Infinity;this.push=function(d){d=d+((1<<b)-1)>>b;a.has(d)&&clearTimeout(a.get(d));a.set(d,setTimeout(function(){a.delete(d);0==a.length?c=-Infinity:d==c&&(c=Math.max(...a.keys()),console.assert(c<d))},1E3));c=Math.max(d,c);return c<<b}}
const fa=new function(){function b(){if(!a){var t=document.createElement("canvas"),v={alpha:1,depth:0,stencil:0,antialias:0,premultipliedAlpha:1,preserveDrawingBuffer:0,preferLowPowerToHighPerformance:0,failIfMajorPerformanceCaveat:0,enableExtensionsByDefault:1,explicitSwapControl:1,renderViaOffscreenBackBuffer:1};let q=t.getContext("webgl2",v);if(q)c=2;else if(q=t.getContext("webgl",v))c=1;else return console.log("No WebGL support. Image mesh will not be drawn."),!1;d=Math.min(q.getParameter(q.MAX_RENDERBUFFER_SIZE),
q.getParameter(q.MAX_TEXTURE_SIZE));function E(I,w,z){w=q.createShader(w);q.shaderSource(w,z);q.compileShader(w);z=q.getShaderInfoLog(w);if(0<z.length)throw z;q.attachShader(I,w)}t=q.createProgram();E(t,q.VERTEX_SHADER,"attribute vec2 vertex;\n                attribute vec2 uv;\n                uniform vec4 mat;\n                uniform vec2 translate;\n                varying vec2 st;\n                void main() {\n                    st = uv;\n                    gl_Position = vec4(mat2(mat) * vertex + translate, 0, 1);\n                }");
E(t,q.FRAGMENT_SHADER,"precision highp float;\n                uniform sampler2D image;\n                varying vec2 st;\n                void main() {\n                    gl_FragColor = texture2D(image, st);\n                }");q.bindAttribLocation(t,0,"vertex");q.bindAttribLocation(t,1,"uv");q.linkProgram(t);v=q.getProgramInfoLog(t);if(0<v.length)throw v;e=q.getUniformLocation(t,"mat");f=q.getUniformLocation(t,"translate");q.useProgram(t);q.bindBuffer(q.ARRAY_BUFFER,q.createBuffer());q.enableVertexAttribArray(0);
q.enableVertexAttribArray(1);q.bindBuffer(q.ELEMENT_ARRAY_BUFFER,q.createBuffer());q.uniform1i(q.getUniformLocation(t,"image"),0);q.pixelStorei(q.UNPACK_PREMULTIPLY_ALPHA_WEBGL,!0);a=q}return!0}let a=null,c=0,d=0,e=null,f=null,h=0,g=0;this.ob=function(){b();return d};this.Ya=function(t){if(!b())return null;const v=a.createTexture();a.bindTexture(a.TEXTURE_2D,v);a.texImage2D(a.TEXTURE_2D,0,a.RGBA,a.RGBA,a.UNSIGNED_BYTE,t);a.texParameteri(a.TEXTURE_2D,a.TEXTURE_WRAP_S,a.CLAMP_TO_EDGE);a.texParameteri(a.TEXTURE_2D,
a.TEXTURE_WRAP_T,a.CLAMP_TO_EDGE);a.texParameteri(a.TEXTURE_2D,a.TEXTURE_MAG_FILTER,a.LINEAR);2==c?(a.texParameteri(a.TEXTURE_2D,a.TEXTURE_MIN_FILTER,a.LINEAR_MIPMAP_LINEAR),a.generateMipmap(a.TEXTURE_2D)):a.texParameteri(a.TEXTURE_2D,a.TEXTURE_MIN_FILTER,a.LINEAR);return v};const l=new ea(8),k=new ea(8),p=new ea(10),r=new ea(10);this.bb=function(t,v,q,E,I){if(b()){var w=l.push(t),z=k.push(v);if(a.canvas.width!=w||a.canvas.height!=z)a.canvas.width=w,a.canvas.height=z;a.viewport(0,z-v,t,v);a.disable(a.SCISSOR_TEST);
a.clearColor(0,0,0,0);a.clear(a.COLOR_BUFFER_BIT);a.enable(a.SCISSOR_TEST);q.sort((y,da)=>da.Oa-y.Oa);w=p.push(E);h!=w&&(a.bufferData(a.ARRAY_BUFFER,8*w,a.DYNAMIC_DRAW),h=w);w=0;for(var J of q)a.bufferSubData(a.ARRAY_BUFFER,w,J.Aa),w+=4*J.Aa.length;console.assert(w==4*E);for(var P of q)a.bufferSubData(a.ARRAY_BUFFER,w,P.Ra),w+=4*P.Ra.length;console.assert(w==8*E);w=r.push(I);g!=w&&(a.bufferData(a.ELEMENT_ARRAY_BUFFER,2*w,a.DYNAMIC_DRAW),g=w);J=0;for(var X of q)a.bufferSubData(a.ELEMENT_ARRAY_BUFFER,
J,X.indices),J+=2*X.indices.length;console.assert(J==2*I);X=0;P=!0;w=J=0;for(const y of q){y.image.va!=X&&(a.bindTexture(a.TEXTURE_2D,y.image.Va||null),X=y.image.va);y.rb?(a.scissor(y.Ea,z-y.Fa-y.Ka,y.yb,y.Ka),P=!0):P&&(a.scissor(0,z-v,t,v),P=!1);q=2/t;const da=-2/v;a.uniform4f(e,y.la[0]*q*y.ra,y.la[1]*da*y.sa,y.la[2]*q*y.ra,y.la[3]*da*y.sa);a.uniform2f(f,y.la[4]*q*y.ra+q*(y.Ea-y.pb*y.ra)-1,y.la[5]*da*y.sa+da*(y.Fa-y.qb*y.sa)+1);a.vertexAttribPointer(0,2,a.FLOAT,!1,0,w);a.vertexAttribPointer(1,2,
a.FLOAT,!1,0,w+4*E);a.drawElements(a.TRIANGLES,y.indices.length,a.UNSIGNED_SHORT,J);w+=4*y.Aa.length;J+=2*y.indices.length}console.assert(w==4*E);console.assert(J==2*I)}};this.canvas=function(){return b()&&a.canvas}};
Rive.onRuntimeInitialized=function(){function b(n){switch(n){case k.srcOver:return"source-over";case k.screen:return"screen";case k.overlay:return"overlay";case k.darken:return"darken";case k.lighten:return"lighten";case k.colorDodge:return"color-dodge";case k.colorBurn:return"color-burn";case k.hardLight:return"hard-light";case k.softLight:return"soft-light";case k.difference:return"difference";case k.exclusion:return"exclusion";case k.multiply:return"multiply";case k.hue:return"hue";case k.saturation:return"saturation";
case k.color:return"color";case k.luminosity:return"luminosity"}}function a(n){return"rgba("+((16711680&n)>>>16)+","+((65280&n)>>>8)+","+((255&n)>>>0)+","+((4278190080&n)>>>24)/255+")"}function c(){0<J.length&&(fa.bb(z.drawWidth(),z.drawHeight(),J,P,X),J=[],X=P=0,z.reset(512,512));for(const n of w){for(const u of n.da)u();n.da=[]}w.clear()}var d=Rive.RenderPaintStyle;const e=Rive.RenderPath,f=Rive.RenderPaint,h=Rive.Renderer,g=Rive.StrokeCap,l=Rive.StrokeJoin,k=Rive.BlendMode,p=d.fill,r=d.stroke,
t=Rive.FillRule.evenOdd;let v=1;var q=Rive.RenderImage.extend("CanvasRenderImage",{__construct:function(){this.__parent.__construct.call(this);this.va=v;v=v+1&2147483647||1},decode:function(n){let u=Xa;u.total++;var F=this,B=new Image;B.src=URL.createObjectURL(new Blob([n],{type:"image/png"}));B.onload=function(){F.Ta=B;F.Va=fa.Ya(B);F.size(B.width,B.height);u.loaded++;if(u.loaded===u.total){const D=u.ready;D&&(D(),u.ready=null)}}}}),E=e.extend("CanvasRenderPath",{__construct:function(){this.__parent.__construct.call(this);
this.ga=new Path2D},reset:function(){this.ga=new Path2D},addPath:function(n,u,F,B,D,G,A){var C=this.ga,R=C.addPath;n=n.ga;const K=new DOMMatrix;K.a=u;K.b=F;K.c=B;K.d=D;K.e=G;K.f=A;R.call(C,n,K)},fillRule:function(n){this.Ca=n},moveTo:function(n,u){this.ga.moveTo(n,u)},lineTo:function(n,u){this.ga.lineTo(n,u)},cubicTo:function(n,u,F,B,D,G){this.ga.bezierCurveTo(n,u,F,B,D,G)},close:function(){this.ga.closePath()}}),I=f.extend("CanvasRenderPaint",{color:function(n){this.Da=a(n)},thickness:function(n){this.Wa=
n},join:function(n){switch(n){case l.miter:this.ua="miter";break;case l.round:this.ua="round";break;case l.bevel:this.ua="bevel"}},cap:function(n){switch(n){case g.butt:this.ta="butt";break;case g.round:this.ta="round";break;case g.square:this.ta="square"}},style:function(n){this.Ua=n},blendMode:function(n){this.Sa=b(n)},linearGradient:function(n,u,F,B){this.oa={Pa:n,Qa:u,Ha:F,Ia:B,za:[]}},radialGradient:function(n,u,F,B){this.oa={Pa:n,Qa:u,Ha:F,Ia:B,za:[],mb:!0}},addStop:function(n,u){this.oa.za.push({color:n,
stop:u})},completeGradient:function(){},draw:function(n,u,F){let B=this.Ua;var D=this.Da,G=this.oa;n.globalCompositeOperation=this.Sa;if(null!=G){D=G.Pa;var A=G.Qa;const R=G.Ha;var C=G.Ia;const K=G.za;G.mb?(G=R-D,C-=A,D=n.createRadialGradient(D,A,0,D,A,Math.sqrt(G*G+C*C))):D=n.createLinearGradient(D,A,R,C);for(let U=0,L=K.length;U<L;U++)A=K[U],D.addColorStop(A.stop,a(A.color));this.Da=D;this.oa=null}switch(B){case r:n.strokeStyle=D;n.lineWidth=this.Wa;n.lineCap=this.ta;n.lineJoin=this.ua;n.stroke(u);
break;case p:n.fillStyle=D,n.fill(u,F)}}});const w=new Set;let z=null,J=[],P=0,X=0;var y=Rive.CanvasRenderer=h.extend("Renderer",{__construct:function(n){this.__parent.__construct.call(this);this.fa=[1,0,0,1,0,0];this.Z=n.getContext("2d");this.Ba=n;this.da=[]},save:function(){this.fa.push(...this.fa.slice(this.fa.length-6));this.da.push(this.Z.save.bind(this.Z))},restore:function(){const n=this.fa.length-6;if(6>n)throw"restore() called without matching save().";this.fa.splice(n);this.da.push(this.Z.restore.bind(this.Z))},
transform:function(n,u,F,B,D,G){const A=this.fa,C=A.length-6;A.splice(C,6,A[C]*n+A[C+2]*u,A[C+1]*n+A[C+3]*u,A[C]*F+A[C+2]*B,A[C+1]*F+A[C+3]*B,A[C]*D+A[C+2]*G+A[C+4],A[C+1]*D+A[C+3]*G+A[C+5]);this.da.push(this.Z.transform.bind(this.Z,n,u,F,B,D,G))},rotate:function(n){const u=Math.sin(n);n=Math.cos(n);this.transform(n,u,-u,n,0,0)},_drawPath:function(n,u){this.da.push(u.draw.bind(u,this.Z,n.ga,n.Ca===t?"evenodd":"nonzero"))},_drawImage:function(n,u,F){var B=n.Ta;if(B){var D=this.Z,G=b(u);this.da.push(function(){D.globalCompositeOperation=
G;D.globalAlpha=F;D.drawImage(B,0,0);D.globalAlpha=1})}},_getMatrix:function(n){const u=this.fa,F=u.length-6;for(let B=0;6>B;++B)n[B]=u[F+B]},_drawImageMesh:function(n,u,F,B,D,G,A,C,R,K){var U=this.Z.canvas.width,L=this.Z.canvas.height;const pb=R-A,qb=K-C;A=Math.max(A,0);C=Math.max(C,0);R=Math.min(R,U);K=Math.min(K,L);const sa=R-A,ta=K-C;console.assert(sa<=Math.min(pb,U));console.assert(ta<=Math.min(qb,L));if(!(0>=sa||0>=ta)){R=sa<pb||ta<qb;U=K=1;var ia=Math.ceil(sa*K),ja=Math.ceil(ta*U);L=fa.ob();
ia>L&&(K*=L/ia,ia=L);ja>L&&(U*=L/ja,ja=L);z||(z=new m.DynamicRectanizer(L),z.reset(512,512));L=z.addRect(ia,ja);0>L&&(c(),w.add(this),L=z.addRect(ia,ja),console.assert(0<=L));var rb=L&65535,sb=L>>16;J.push({la:this.fa.slice(this.fa.length-6),image:n,Ea:rb,Fa:sb,pb:A,qb:C,yb:ia,Ka:ja,ra:K,sa:U,Aa:new Float32Array(B),Ra:new Float32Array(D),indices:new Uint16Array(G),rb:R,Oa:n.va<<1|(R?1:0)});P+=B.length;X+=G.length;var na=this.Z,Yb=b(u);this.da.push(function(){na.save();na.resetTransform();na.globalCompositeOperation=
Yb;na.globalAlpha=F;na.drawImage(fa.canvas(),rb,sb,ia,ja,A,C,sa,ta);na.restore()})}},_clipPath:function(n){this.da.push(this.Z.clip.bind(this.Z,n.ga,n.Ca===t?"evenodd":"nonzero"))},clear:function(){w.add(this);this.da.push(this.Z.clearRect.bind(this.Z,0,0,this.Ba.width,this.Ba.height))},flush:function(){},translate:function(n,u){this.transform(1,0,0,1,n,u)}});Rive.makeRenderer=function(n){return new y(n)};Rive.renderFactory={makeRenderPaint:function(){return new I},makeRenderPath:function(){return new E},
makeRenderImage:function(){return new q}};let da=Rive.load,Xa=null;Rive.load=function(n){return new Promise(function(u){let F=null;Xa={total:0,loaded:0,ready:function(){u(F)}};F=da(n);0==Xa.total&&u(F)})};d=new ca;Rive.requestAnimationFrame=d.requestAnimationFrame.bind(d);Rive.cancelAnimationFrame=d.cancelAnimationFrame.bind(d);Rive.enableFPSCounter=d.cb.bind(d);Rive.disableFPSCounter=d.$a;d.La=c;Rive.cleanup=function(){z&&z.delete()}};
var ha=Object.assign({},m),ka="object"==typeof window,la="function"==typeof importScripts,x="",ma,oa;
if(ka||la)la?x=self.location.href:"undefined"!=typeof document&&document.currentScript&&(x=document.currentScript.src),_scriptDir&&(x=_scriptDir),0!==x.indexOf("blob:")?x=x.substr(0,x.replace(/[?#].*/,"").lastIndexOf("/")+1):x="",la&&(oa=b=>{var a=new XMLHttpRequest;a.open("GET",b,!1);a.responseType="arraybuffer";a.send(null);return new Uint8Array(a.response)}),ma=(b,a,c)=>{var d=new XMLHttpRequest;d.open("GET",b,!0);d.responseType="arraybuffer";d.onload=()=>{200==d.status||0==d.status&&d.response?
a(d.response):c()};d.onerror=c;d.send(null)};var pa=m.print||console.log.bind(console),qa=m.printErr||console.warn.bind(console);Object.assign(m,ha);ha=null;var ra;m.wasmBinary&&(ra=m.wasmBinary);var noExitRuntime=m.noExitRuntime||!0;"object"!=typeof WebAssembly&&ua("no native wasm support detected");var va,wa=!1,xa="undefined"!=typeof TextDecoder?new TextDecoder("utf8"):void 0;
function ya(b,a,c){var d=a+c;for(c=a;b[c]&&!(c>=d);)++c;if(16<c-a&&b.buffer&&xa)return xa.decode(b.subarray(a,c));for(d="";a<c;){var e=b[a++];if(e&128){var f=b[a++]&63;if(192==(e&224))d+=String.fromCharCode((e&31)<<6|f);else{var h=b[a++]&63;e=224==(e&240)?(e&15)<<12|f<<6|h:(e&7)<<18|f<<12|h<<6|b[a++]&63;65536>e?d+=String.fromCharCode(e):(e-=65536,d+=String.fromCharCode(55296|e>>10,56320|e&1023))}}else d+=String.fromCharCode(e)}return d}var za,Aa,H,Ba,Ca,Da,M,Ea,Fa;
function Ga(){var b=va.buffer;za=b;m.HEAP8=Aa=new Int8Array(b);m.HEAP16=Ba=new Int16Array(b);m.HEAP32=Da=new Int32Array(b);m.HEAPU8=H=new Uint8Array(b);m.HEAPU16=Ca=new Uint16Array(b);m.HEAPU32=M=new Uint32Array(b);m.HEAPF32=Ea=new Float32Array(b);m.HEAPF64=Fa=new Float64Array(b)}var Ha,Ia=[],Ja=[],Ka=[];function La(){var b=m.preRun.shift();Ia.unshift(b)}var Ma=0,Na=null,Oa=null;
function ua(b){if(m.onAbort)m.onAbort(b);b="Aborted("+b+")";qa(b);wa=!0;b=new WebAssembly.RuntimeError(b+". Build with -sASSERTIONS for more info.");ba(b);throw b;}function Pa(){return N.startsWith("data:application/octet-stream;base64,")}var N;N="canvas_advanced.wasm";if(!Pa()){var Qa=N;N=m.locateFile?m.locateFile(Qa,x):x+Qa}function Ra(){var b=N;try{if(b==N&&ra)return new Uint8Array(ra);if(oa)return oa(b);throw"both async and sync fetching of the wasm failed";}catch(a){ua(a)}}
function Sa(){if(!ra&&(ka||la)){if("function"==typeof fetch&&!N.startsWith("file://"))return fetch(N,{credentials:"same-origin"}).then(function(b){if(!b.ok)throw"failed to load wasm binary file at '"+N+"'";return b.arrayBuffer()}).catch(function(){return Ra()});if(ma)return new Promise(function(b,a){ma(N,function(c){b(new Uint8Array(c))},a)})}return Promise.resolve().then(function(){return Ra()})}function Ta(b){for(;0<b.length;)b.shift()(m)}
function Ua(b){if(void 0===b)return"_unknown";b=b.replace(/[^a-zA-Z0-9_]/g,"$");var a=b.charCodeAt(0);return 48<=a&&57>=a?"_"+b:b}function Va(b,a){b=Ua(b);return function(){null;return a.apply(this,arguments)}}var O=[{},{value:void 0},{value:null},{value:!0},{value:!1}],Wa=[];
function Ya(b){var a=Error,c=Va(b,function(d){this.name=b;this.message=d;d=Error(d).stack;void 0!==d&&(this.stack=this.toString()+"\n"+d.replace(/^Error(:[^\n]*)?\n/,""))});c.prototype=Object.create(a.prototype);c.prototype.constructor=c;c.prototype.toString=function(){return void 0===this.message?this.name:this.name+": "+this.message};return c}var Za=void 0;function Q(b){throw new Za(b);}
var $a=b=>{b||Q("Cannot use deleted val. handle = "+b);return O[b].value},S=b=>{switch(b){case void 0:return 1;case null:return 2;case !0:return 3;case !1:return 4;default:var a=Wa.length?Wa.pop():O.length;O[a]={ya:1,value:b};return a}},ab=void 0,bb=void 0;function T(b){for(var a="";H[b];)a+=bb[H[b++]];return a}var cb=[];function db(){for(;cb.length;){var b=cb.pop();b.U.ka=!1;b["delete"]()}}var eb=void 0,V={};
function fb(b,a){for(void 0===a&&Q("ptr should not be undefined");b.$;)a=b.na(a),b=b.$;return a}var gb={};function hb(b){b=ib(b);var a=T(b);jb(b);return a}function kb(b,a){var c=gb[b];void 0===c&&Q(a+" has unknown type "+hb(b));return c}function lb(){}var mb=!1;function nb(b){--b.count.value;0===b.count.value&&(b.aa?b.ca.ha(b.aa):b.X.V.ha(b.W))}function ob(b,a,c){if(a===c)return b;if(void 0===c.$)return null;b=ob(b,a,c.$);return null===b?null:c.ab(b)}var tb={};
function ub(b,a){a=fb(b,a);return V[a]}var vb=void 0;function wb(b){throw new vb(b);}function xb(b,a){a.X&&a.W||wb("makeClassHandle requires ptr and ptrType");!!a.ca!==!!a.aa&&wb("Both smartPtrType and smartPtr must be specified");a.count={value:1};return yb(Object.create(b,{U:{value:a}}))}
function yb(b){if("undefined"===typeof FinalizationRegistry)return yb=a=>a,b;mb=new FinalizationRegistry(a=>{nb(a.U)});yb=a=>{var c=a.U;c.aa&&mb.register(a,{U:c},a);return a};lb=a=>{mb.unregister(a)};return yb(b)}var zb={};function Ab(b){for(;b.length;){var a=b.pop();b.pop()(a)}}function Bb(b){return this.fromWireType(Da[b>>2])}var Cb={},Db={};
function W(b,a,c){function d(g){g=c(g);g.length!==b.length&&wb("Mismatched type converter count");for(var l=0;l<b.length;++l)Y(b[l],g[l])}b.forEach(function(g){Db[g]=a});var e=Array(a.length),f=[],h=0;a.forEach((g,l)=>{gb.hasOwnProperty(g)?e[l]=gb[g]:(f.push(g),Cb.hasOwnProperty(g)||(Cb[g]=[]),Cb[g].push(()=>{e[l]=gb[g];++h;h===f.length&&d(e)}))});0===f.length&&d(e)}
function Eb(b){switch(b){case 1:return 0;case 2:return 1;case 4:return 2;case 8:return 3;default:throw new TypeError("Unknown type size: "+b);}}
function Y(b,a,c={}){if(!("argPackAdvance"in a))throw new TypeError("registerType registeredInstance requires argPackAdvance");var d=a.name;b||Q('type "'+d+'" must have a positive integer typeid pointer');if(gb.hasOwnProperty(b)){if(c.lb)return;Q("Cannot register type '"+d+"' twice")}gb[b]=a;delete Db[b];Cb.hasOwnProperty(b)&&(a=Cb[b],delete Cb[b],a.forEach(e=>e()))}function Fb(b){Q(b.U.X.V.name+" instance already deleted")}function Gb(){}
function Hb(b,a,c){if(void 0===b[a].Y){var d=b[a];b[a]=function(){b[a].Y.hasOwnProperty(arguments.length)||Q("Function '"+c+"' called with an invalid number of arguments ("+arguments.length+") - expects one of ("+b[a].Y+")!");return b[a].Y[arguments.length].apply(this,arguments)};b[a].Y=[];b[a].Y[d.ja]=d}}
function Ib(b,a,c){m.hasOwnProperty(b)?((void 0===c||void 0!==m[b].Y&&void 0!==m[b].Y[c])&&Q("Cannot register public name '"+b+"' twice"),Hb(m,b,b),m.hasOwnProperty(c)&&Q("Cannot register multiple overloads of a function with the same number of arguments ("+c+")!"),m[b].Y[c]=a):(m[b]=a,void 0!==c&&(m[b].zb=c))}function Jb(b,a,c,d,e,f,h,g){this.name=b;this.constructor=a;this.ea=c;this.ha=d;this.$=e;this.gb=f;this.na=h;this.ab=g;this.Ma=[]}
function Kb(b,a,c){for(;a!==c;)a.na||Q("Expected null or instance of "+c.name+", got an instance of "+a.name),b=a.na(b),a=a.$;return b}function Lb(b,a){if(null===a)return this.wa&&Q("null is not a valid "+this.name),0;a.U||Q('Cannot pass "'+Mb(a)+'" as a '+this.name);a.U.W||Q("Cannot pass deleted object as a pointer of type "+this.name);return Kb(a.U.W,a.U.X.V,this.V)}
function Nb(b,a){if(null===a){this.wa&&Q("null is not a valid "+this.name);if(this.qa){var c=this.xa();null!==b&&b.push(this.ha,c);return c}return 0}a.U||Q('Cannot pass "'+Mb(a)+'" as a '+this.name);a.U.W||Q("Cannot pass deleted object as a pointer of type "+this.name);!this.pa&&a.U.X.pa&&Q("Cannot convert argument of type "+(a.U.ca?a.U.ca.name:a.U.X.name)+" to parameter type "+this.name);c=Kb(a.U.W,a.U.X.V,this.V);if(this.qa)switch(void 0===a.U.aa&&Q("Passing raw pointer to smart pointer is illegal"),
this.xb){case 0:a.U.ca===this?c=a.U.aa:Q("Cannot convert argument of type "+(a.U.ca?a.U.ca.name:a.U.X.name)+" to parameter type "+this.name);break;case 1:c=a.U.aa;break;case 2:if(a.U.ca===this)c=a.U.aa;else{var d=a.clone();c=this.tb(c,S(function(){d["delete"]()}));null!==b&&b.push(this.ha,c)}break;default:Q("Unsupporting sharing policy")}return c}
function Ob(b,a){if(null===a)return this.wa&&Q("null is not a valid "+this.name),0;a.U||Q('Cannot pass "'+Mb(a)+'" as a '+this.name);a.U.W||Q("Cannot pass deleted object as a pointer of type "+this.name);a.U.X.pa&&Q("Cannot convert argument of type "+a.U.X.name+" to parameter type "+this.name);return Kb(a.U.W,a.U.X.V,this.V)}
function Pb(b,a,c,d){this.name=b;this.V=a;this.wa=c;this.pa=d;this.qa=!1;this.ha=this.tb=this.xa=this.Na=this.xb=this.sb=void 0;void 0!==a.$?this.toWireType=Nb:(this.toWireType=d?Lb:Ob,this.ba=null)}function Qb(b,a,c){m.hasOwnProperty(b)||wb("Replacing nonexistant public symbol");void 0!==m[b].Y&&void 0!==c?m[b].Y[c]=a:(m[b]=a,m[b].ja=c)}
function Rb(b,a){var c=[];return function(){c.length=0;Object.assign(c,arguments);if(b.includes("j")){var d=m["dynCall_"+b];d=c&&c.length?d.apply(null,[a].concat(c)):d.call(null,a)}else d=Ha.get(a).apply(null,c);return d}}function Z(b,a){b=T(b);var c=b.includes("j")?Rb(b,a):Ha.get(a);"function"!=typeof c&&Q("unknown function pointer with signature "+b+": "+a);return c}var Sb=void 0;
function Tb(b,a){function c(f){e[f]||gb[f]||(Db[f]?Db[f].forEach(c):(d.push(f),e[f]=!0))}var d=[],e={};a.forEach(c);throw new Sb(b+": "+d.map(hb).join([", "]));}
function Ub(b,a,c,d,e){var f=a.length;2>f&&Q("argTypes array size mismatch! Must at least get return value and 'this' types!");var h=null!==a[1]&&null!==c,g=!1;for(c=1;c<a.length;++c)if(null!==a[c]&&void 0===a[c].ba){g=!0;break}var l="void"!==a[0].name,k=f-2,p=Array(k),r=[],t=[];return function(){arguments.length!==k&&Q("function "+b+" called with "+arguments.length+" arguments, expected "+k+" args!");t.length=0;r.length=h?2:1;r[0]=e;if(h){var v=a[1].toWireType(t,this);r[1]=v}for(var q=0;q<k;++q)p[q]=
a[q+2].toWireType(t,arguments[q]),r.push(p[q]);q=d.apply(null,r);if(g)Ab(t);else for(var E=h?1:2;E<a.length;E++){var I=1===E?v:p[E-2];null!==a[E].ba&&a[E].ba(I)}v=l?a[0].fromWireType(q):void 0;return v}}function Vb(b,a){for(var c=[],d=0;d<b;d++)c.push(M[a+4*d>>2]);return c}
function Wb(b,a,c){b instanceof Object||Q(c+' with invalid "this": '+b);b instanceof a.V.constructor||Q(c+' incompatible with "this" of type '+b.constructor.name);b.U.W||Q("cannot call emscripten binding method "+c+" on deleted object");return Kb(b.U.W,b.U.X.V,a.V)}function Xb(b){4<b&&0===--O[b].ya&&(O[b]=void 0,Wa.push(b))}
function Zb(b,a,c){switch(a){case 0:return function(d){return this.fromWireType((c?Aa:H)[d])};case 1:return function(d){return this.fromWireType((c?Ba:Ca)[d>>1])};case 2:return function(d){return this.fromWireType((c?Da:M)[d>>2])};default:throw new TypeError("Unknown integer type: "+b);}}function Mb(b){if(null===b)return"null";var a=typeof b;return"object"===a||"array"===a||"function"===a?b.toString():""+b}
function $b(b,a){switch(a){case 2:return function(c){return this.fromWireType(Ea[c>>2])};case 3:return function(c){return this.fromWireType(Fa[c>>3])};default:throw new TypeError("Unknown float type: "+b);}}
function ac(b,a,c){switch(a){case 0:return c?function(d){return Aa[d]}:function(d){return H[d]};case 1:return c?function(d){return Ba[d>>1]}:function(d){return Ca[d>>1]};case 2:return c?function(d){return Da[d>>2]}:function(d){return M[d>>2]};default:throw new TypeError("Unknown integer type: "+b);}}var bc="undefined"!=typeof TextDecoder?new TextDecoder("utf-16le"):void 0;
function cc(b,a){var c=b>>1;for(var d=c+a/2;!(c>=d)&&Ca[c];)++c;c<<=1;if(32<c-b&&bc)return bc.decode(H.subarray(b,c));c="";for(d=0;!(d>=a/2);++d){var e=Ba[b+2*d>>1];if(0==e)break;c+=String.fromCharCode(e)}return c}function dc(b,a,c){void 0===c&&(c=2147483647);if(2>c)return 0;c-=2;var d=a;c=c<2*b.length?c/2:b.length;for(var e=0;e<c;++e)Ba[a>>1]=b.charCodeAt(e),a+=2;Ba[a>>1]=0;return a-d}function ec(b){return 2*b.length}
function fc(b,a){for(var c=0,d="";!(c>=a/4);){var e=Da[b+4*c>>2];if(0==e)break;++c;65536<=e?(e-=65536,d+=String.fromCharCode(55296|e>>10,56320|e&1023)):d+=String.fromCharCode(e)}return d}function gc(b,a,c){void 0===c&&(c=2147483647);if(4>c)return 0;var d=a;c=d+c-4;for(var e=0;e<b.length;++e){var f=b.charCodeAt(e);if(55296<=f&&57343>=f){var h=b.charCodeAt(++e);f=65536+((f&1023)<<10)|h&1023}Da[a>>2]=f;a+=4;if(a+4>c)break}Da[a>>2]=0;return a-d}
function hc(b){for(var a=0,c=0;c<b.length;++c){var d=b.charCodeAt(c);55296<=d&&57343>=d&&++c;a+=4}return a}var ic={};function jc(b){var a=ic[b];return void 0===a?T(b):a}var kc=[];function lc(b){var a=kc.length;kc.push(b);return a}function mc(b,a){for(var c=Array(b),d=0;d<b;++d)c[d]=kb(M[a+4*d>>2],"parameter "+d);return c}var nc=[],oc=[null,[],[]];Za=m.BindingError=Ya("BindingError");m.count_emval_handles=function(){for(var b=0,a=5;a<O.length;++a)void 0!==O[a]&&++b;return b};
m.get_first_emval=function(){for(var b=5;b<O.length;++b)if(void 0!==O[b])return O[b];return null};ab=m.PureVirtualError=Ya("PureVirtualError");for(var pc=Array(256),qc=0;256>qc;++qc)pc[qc]=String.fromCharCode(qc);bb=pc;m.getInheritedInstanceCount=function(){return Object.keys(V).length};m.getLiveInheritedInstances=function(){var b=[],a;for(a in V)V.hasOwnProperty(a)&&b.push(V[a]);return b};m.flushPendingDeletes=db;m.setDelayFunction=function(b){eb=b;cb.length&&eb&&eb(db)};vb=m.InternalError=Ya("InternalError");
Gb.prototype.isAliasOf=function(b){if(!(this instanceof Gb&&b instanceof Gb))return!1;var a=this.U.X.V,c=this.U.W,d=b.U.X.V;for(b=b.U.W;a.$;)c=a.na(c),a=a.$;for(;d.$;)b=d.na(b),d=d.$;return a===d&&c===b};Gb.prototype.clone=function(){this.U.W||Fb(this);if(this.U.ma)return this.U.count.value+=1,this;var b=yb,a=Object,c=a.create,d=Object.getPrototypeOf(this),e=this.U;b=b(c.call(a,d,{U:{value:{count:e.count,ka:e.ka,ma:e.ma,W:e.W,X:e.X,aa:e.aa,ca:e.ca}}}));b.U.count.value+=1;b.U.ka=!1;return b};
Gb.prototype["delete"]=function(){this.U.W||Fb(this);this.U.ka&&!this.U.ma&&Q("Object already scheduled for deletion");lb(this);nb(this.U);this.U.ma||(this.U.aa=void 0,this.U.W=void 0)};Gb.prototype.isDeleted=function(){return!this.U.W};Gb.prototype.deleteLater=function(){this.U.W||Fb(this);this.U.ka&&!this.U.ma&&Q("Object already scheduled for deletion");cb.push(this);1===cb.length&&eb&&eb(db);this.U.ka=!0;return this};Pb.prototype.hb=function(b){this.Na&&(b=this.Na(b));return b};
Pb.prototype.Ga=function(b){this.ha&&this.ha(b)};Pb.prototype.argPackAdvance=8;Pb.prototype.readValueFromPointer=Bb;Pb.prototype.deleteObject=function(b){if(null!==b)b["delete"]()};
Pb.prototype.fromWireType=function(b){function a(){return this.qa?xb(this.V.ea,{X:this.sb,W:c,ca:this,aa:b}):xb(this.V.ea,{X:this,W:b})}var c=this.hb(b);if(!c)return this.Ga(b),null;var d=ub(this.V,c);if(void 0!==d){if(0===d.U.count.value)return d.U.W=c,d.U.aa=b,d.clone();d=d.clone();this.Ga(b);return d}d=this.V.gb(c);d=tb[d];if(!d)return a.call(this);d=this.pa?d.Xa:d.pointerType;var e=ob(c,this.V,d.V);return null===e?a.call(this):this.qa?xb(d.V.ea,{X:d,W:e,ca:this,aa:b}):xb(d.V.ea,{X:d,W:e})};
Sb=m.UnboundTypeError=Ya("UnboundTypeError");
var sc={l:function(b,a,c){b=T(b);a=kb(a,"wrapper");c=$a(c);var d=[].slice,e=a.V,f=e.ea,h=e.$.ea,g=e.$.constructor;b=Va(b,function(){e.$.Ma.forEach(function(k){if(this[k]===h[k])throw new ab("Pure virtual function "+k+" must be implemented in JavaScript");}.bind(this));Object.defineProperty(this,"__parent",{value:f});this.__construct.apply(this,d.call(arguments))});f.__construct=function(){this===f&&Q("Pass correct 'this' to __construct");var k=g.implement.apply(void 0,[this].concat(d.call(arguments)));
lb(k);var p=k.U;k.notifyOnDestruction();p.ma=!0;Object.defineProperties(this,{U:{value:p}});yb(this);k=p.W;k=fb(e,k);V.hasOwnProperty(k)?Q("Tried to register registered instance: "+k):V[k]=this};f.__destruct=function(){this===f&&Q("Pass correct 'this' to __destruct");lb(this);var k=this.U.W;k=fb(e,k);V.hasOwnProperty(k)?delete V[k]:Q("Tried to unregister unregistered instance: "+k)};b.prototype=Object.create(f);for(var l in c)b.prototype[l]=c[l];return S(b)},I:function(b){var a=zb[b];delete zb[b];
var c=a.xa,d=a.ha,e=a.Ja,f=e.map(h=>h.kb).concat(e.map(h=>h.vb));W([b],f,h=>{var g={};e.forEach((l,k)=>{var p=h[k],r=l.ib,t=l.jb,v=h[k+e.length],q=l.ub,E=l.wb;g[l.eb]={read:I=>p.fromWireType(r(t,I)),write:(I,w)=>{var z=[];q(E,I,v.toWireType(z,w));Ab(z)}}});return[{name:a.name,fromWireType:function(l){var k={},p;for(p in g)k[p]=g[p].read(l);d(l);return k},toWireType:function(l,k){for(var p in g)if(!(p in k))throw new TypeError('Missing field:  "'+p+'"');var r=c();for(p in g)g[p].write(r,k[p]);null!==
l&&l.push(d,r);return r},argPackAdvance:8,readValueFromPointer:Bb,ba:d}]})},x:function(){},B:function(b,a,c,d,e){var f=Eb(c);a=T(a);Y(b,{name:a,fromWireType:function(h){return!!h},toWireType:function(h,g){return g?d:e},argPackAdvance:8,readValueFromPointer:function(h){if(1===c)var g=Aa;else if(2===c)g=Ba;else if(4===c)g=Da;else throw new TypeError("Unknown boolean type size: "+a);return this.fromWireType(g[h>>f])},ba:null})},c:function(b,a,c,d,e,f,h,g,l,k,p,r,t){p=T(p);f=Z(e,f);g&&(g=Z(h,g));k&&(k=
Z(l,k));t=Z(r,t);var v=Ua(p);Ib(v,function(){Tb("Cannot construct "+p+" due to unbound types",[d])});W([b,a,c],d?[d]:[],function(q){q=q[0];if(d){var E=q.V;var I=E.ea}else I=Gb.prototype;q=Va(v,function(){if(Object.getPrototypeOf(this)!==w)throw new Za("Use 'new' to construct "+p);if(void 0===z.ia)throw new Za(p+" has no accessible constructor");var P=z.ia[arguments.length];if(void 0===P)throw new Za("Tried to invoke ctor of "+p+" with invalid number of parameters ("+arguments.length+") - expected ("+
Object.keys(z.ia).toString()+") parameters instead!");return P.apply(this,arguments)});var w=Object.create(I,{constructor:{value:q}});q.prototype=w;var z=new Jb(p,q,w,t,E,f,g,k);E=new Pb(p,z,!0,!1);I=new Pb(p+"*",z,!1,!1);var J=new Pb(p+" const*",z,!1,!0);tb[b]={pointerType:I,Xa:J};Qb(v,q);return[E,I,J]})},h:function(b,a,c,d,e,f,h){var g=Vb(c,d);a=T(a);f=Z(e,f);W([],[b],function(l){function k(){Tb("Cannot call "+p+" due to unbound types",g)}l=l[0];var p=l.name+"."+a;a.startsWith("@@")&&(a=Symbol[a.substring(2)]);
var r=l.V.constructor;void 0===r[a]?(k.ja=c-1,r[a]=k):(Hb(r,a,p),r[a].Y[c-1]=k);W([],g,function(t){t=Ub(p,[t[0],null].concat(t.slice(1)),null,f,h);void 0===r[a].Y?(t.ja=c-1,r[a]=t):r[a].Y[c-1]=t;return[]});return[]})},n:function(b,a,c,d,e,f,h,g){a=T(a);f=Z(e,f);W([],[b],function(l){l=l[0];var k=l.name+"."+a,p={get:function(){Tb("Cannot access "+k+" due to unbound types",[c])},enumerable:!0,configurable:!0};p.set=g?()=>{Tb("Cannot access "+k+" due to unbound types",[c])}:()=>{Q(k+" is a read-only property")};
Object.defineProperty(l.V.constructor,a,p);W([],[c],function(r){r=r[0];var t={get:function(){return r.fromWireType(f(d))},enumerable:!0};g&&(g=Z(h,g),t.set=v=>{var q=[];g(d,r.toWireType(q,v));Ab(q)});Object.defineProperty(l.V.constructor,a,t);return[]});return[]})},k:function(b,a,c,d,e,f){0<a||ua();var h=Vb(a,c);e=Z(d,e);W([],[b],function(g){g=g[0];var l="constructor "+g.name;void 0===g.V.ia&&(g.V.ia=[]);if(void 0!==g.V.ia[a-1])throw new Za("Cannot register multiple constructors with identical number of parameters ("+
(a-1)+") for class '"+g.name+"'! Overload resolution is currently only performed using the parameter count, not actual type info!");g.V.ia[a-1]=()=>{Tb("Cannot construct "+g.name+" due to unbound types",h)};W([],h,function(k){k.splice(1,0,null);g.V.ia[a-1]=Ub(l,k,null,e,f);return[]});return[]})},a:function(b,a,c,d,e,f,h,g){var l=Vb(c,d);a=T(a);f=Z(e,f);W([],[b],function(k){function p(){Tb("Cannot call "+r+" due to unbound types",l)}k=k[0];var r=k.name+"."+a;a.startsWith("@@")&&(a=Symbol[a.substring(2)]);
g&&k.V.Ma.push(a);var t=k.V.ea,v=t[a];void 0===v||void 0===v.Y&&v.className!==k.name&&v.ja===c-2?(p.ja=c-2,p.className=k.name,t[a]=p):(Hb(t,a,r),t[a].Y[c-2]=p);W([],l,function(q){q=Ub(r,q,k,f,h);void 0===t[a].Y?(q.ja=c-2,t[a]=q):t[a].Y[c-2]=q;return[]});return[]})},b:function(b,a,c,d,e,f,h,g,l,k){a=T(a);e=Z(d,e);W([],[b],function(p){p=p[0];var r=p.name+"."+a,t={get:function(){Tb("Cannot access "+r+" due to unbound types",[c,h])},enumerable:!0,configurable:!0};t.set=l?()=>{Tb("Cannot access "+r+" due to unbound types",
[c,h])}:()=>{Q(r+" is a read-only property")};Object.defineProperty(p.V.ea,a,t);W([],l?[c,h]:[c],function(v){var q=v[0],E={get:function(){var w=Wb(this,p,r+" getter");return q.fromWireType(e(f,w))},enumerable:!0};if(l){l=Z(g,l);var I=v[1];E.set=function(w){var z=Wb(this,p,r+" setter"),J=[];l(k,z,I.toWireType(J,w));Ab(J)}}Object.defineProperty(p.V.ea,a,E);return[]});return[]})},A:function(b,a){a=T(a);Y(b,{name:a,fromWireType:function(c){var d=$a(c);Xb(c);return d},toWireType:function(c,d){return S(d)},
argPackAdvance:8,readValueFromPointer:Bb,ba:null})},j:function(b,a,c,d){function e(){}c=Eb(c);a=T(a);e.values={};Y(b,{name:a,constructor:e,fromWireType:function(f){return this.constructor.values[f]},toWireType:function(f,h){return h.value},argPackAdvance:8,readValueFromPointer:Zb(a,c,d),ba:null});Ib(a,e)},i:function(b,a,c){var d=kb(b,"enum");a=T(a);b=d.constructor;d=Object.create(d.constructor.prototype,{value:{value:c},constructor:{value:Va(d.name+"_"+a,function(){})}});b.values[c]=d;b[a]=d},r:function(b,
a,c){c=Eb(c);a=T(a);Y(b,{name:a,fromWireType:function(d){return d},toWireType:function(d,e){return e},argPackAdvance:8,readValueFromPointer:$b(a,c),ba:null})},q:function(b,a,c,d,e,f){var h=Vb(a,c);b=T(b);e=Z(d,e);Ib(b,function(){Tb("Cannot call "+b+" due to unbound types",h)},a-1);W([],h,function(g){Qb(b,Ub(b,[g[0],null].concat(g.slice(1)),null,e,f),a-1);return[]})},g:function(b,a,c,d,e){a=T(a);-1===e&&(e=4294967295);e=Eb(c);var f=g=>g;if(0===d){var h=32-8*c;f=g=>g<<h>>>h}c=a.includes("unsigned")?
function(g,l){return l>>>0}:function(g,l){return l};Y(b,{name:a,fromWireType:f,toWireType:c,argPackAdvance:8,readValueFromPointer:ac(a,e,0!==d),ba:null})},d:function(b,a,c){function d(f){f>>=2;var h=M;return new e(za,h[f+1],h[f])}var e=[Int8Array,Uint8Array,Int16Array,Uint16Array,Int32Array,Uint32Array,Float32Array,Float64Array][a];c=T(c);Y(b,{name:c,fromWireType:d,argPackAdvance:8,readValueFromPointer:d},{lb:!0})},s:function(b,a){a=T(a);var c="std::string"===a;Y(b,{name:a,fromWireType:function(d){var e=
M[d>>2],f=d+4;if(c)for(var h=f,g=0;g<=e;++g){var l=f+g;if(g==e||0==H[l]){h=h?ya(H,h,l-h):"";if(void 0===k)var k=h;else k+=String.fromCharCode(0),k+=h;h=l+1}}else{k=Array(e);for(g=0;g<e;++g)k[g]=String.fromCharCode(H[f+g]);k=k.join("")}jb(d);return k},toWireType:function(d,e){e instanceof ArrayBuffer&&(e=new Uint8Array(e));var f,h="string"==typeof e;h||e instanceof Uint8Array||e instanceof Uint8ClampedArray||e instanceof Int8Array||Q("Cannot pass non-string to std::string");var g;if(c&&h)for(f=g=0;f<
e.length;++f){var l=e.charCodeAt(f);127>=l?g++:2047>=l?g+=2:55296<=l&&57343>=l?(g+=4,++f):g+=3}else g=e.length;f=g;g=rc(4+f+1);l=g+4;M[g>>2]=f;if(c&&h){if(h=l,l=f+1,f=H,0<l){l=h+l-1;for(var k=0;k<e.length;++k){var p=e.charCodeAt(k);if(55296<=p&&57343>=p){var r=e.charCodeAt(++k);p=65536+((p&1023)<<10)|r&1023}if(127>=p){if(h>=l)break;f[h++]=p}else{if(2047>=p){if(h+1>=l)break;f[h++]=192|p>>6}else{if(65535>=p){if(h+2>=l)break;f[h++]=224|p>>12}else{if(h+3>=l)break;f[h++]=240|p>>18;f[h++]=128|p>>12&63}f[h++]=
128|p>>6&63}f[h++]=128|p&63}}f[h]=0}}else if(h)for(h=0;h<f;++h)k=e.charCodeAt(h),255<k&&(jb(l),Q("String has UTF-16 code units that do not fit in 8 bits")),H[l+h]=k;else for(h=0;h<f;++h)H[l+h]=e[h];null!==d&&d.push(jb,g);return g},argPackAdvance:8,readValueFromPointer:Bb,ba:function(d){jb(d)}})},p:function(b,a,c){c=T(c);if(2===a){var d=cc;var e=dc;var f=ec;var h=()=>Ca;var g=1}else 4===a&&(d=fc,e=gc,f=hc,h=()=>M,g=2);Y(b,{name:c,fromWireType:function(l){for(var k=M[l>>2],p=h(),r,t=l+4,v=0;v<=k;++v){var q=
l+4+v*a;if(v==k||0==p[q>>g])t=d(t,q-t),void 0===r?r=t:(r+=String.fromCharCode(0),r+=t),t=q+a}jb(l);return r},toWireType:function(l,k){"string"!=typeof k&&Q("Cannot pass non-string to C++ string type "+c);var p=f(k),r=rc(4+p+a);M[r>>2]=p>>g;e(k,r+4,p+a);null!==l&&l.push(jb,r);return r},argPackAdvance:8,readValueFromPointer:Bb,ba:function(l){jb(l)}})},K:function(b,a,c,d,e,f){zb[b]={name:T(a),xa:Z(c,d),ha:Z(e,f),Ja:[]}},J:function(b,a,c,d,e,f,h,g,l,k){zb[b].Ja.push({eb:T(a),kb:c,ib:Z(d,e),jb:f,vb:h,
ub:Z(g,l),wb:k})},C:function(b,a){a=T(a);Y(b,{nb:!0,name:a,argPackAdvance:0,fromWireType:function(){},toWireType:function(){}})},m:function(b,a,c){b=$a(b);a=kb(a,"emval::as");var d=[],e=S(d);M[c>>2]=e;return a.toWireType(d,b)},t:function(b,a,c,d,e){b=kc[b];a=$a(a);c=jc(c);var f=[];M[d>>2]=S(f);return b(a,c,f,e)},f:function(b,a,c,d){b=kc[b];a=$a(a);c=jc(c);b(a,c,null,d)},L:Xb,e:function(b,a){var c=mc(b,a),d=c[0];a=d.name+"_$"+c.slice(1).map(function(h){return h.name}).join("_")+"$";var e=nc[a];if(void 0!==
e)return e;var f=Array(b-1);e=lc((h,g,l,k)=>{for(var p=0,r=0;r<b-1;++r)f[r]=c[r+1].readValueFromPointer(k+p),p+=c[r+1].argPackAdvance;h=h[g].apply(h,f);for(r=0;r<b-1;++r)c[r+1].Za&&c[r+1].Za(f[r]);if(!d.nb)return d.toWireType(l,h)});return nc[a]=e},D:function(b){b=jc(b);return S(m[b])},H:function(b,a){b=$a(b);a=$a(a);return S(b[a])},E:function(b){4<b&&(O[b].ya+=1)},G:function(b){return S(jc(b))},F:function(b){var a=$a(b);Ab(a);Xb(b)},u:function(b,a){b=kb(b,"_emval_take_value");b=b.readValueFromPointer(a);
return S(b)},v:function(){ua("")},o:function(b){var a=H.length;b>>>=0;if(2147483648<b)return!1;for(var c=1;4>=c;c*=2){var d=a*(1+.2/c);d=Math.min(d,b+100663296);var e=Math;d=Math.max(b,d);e=e.min.call(e,2147483648,d+(65536-d%65536)%65536);a:{try{va.grow(e-za.byteLength+65535>>>16);Ga();var f=1;break a}catch(h){}f=void 0}if(f)return!0}return!1},z:function(){return 52},w:function(){return 70},y:function(b,a,c,d){for(var e=0,f=0;f<c;f++){var h=M[a>>2],g=M[a+4>>2];a+=8;for(var l=0;l<g;l++){var k=H[h+
l],p=oc[b];0===k||10===k?((1===b?pa:qa)(ya(p,0)),p.length=0):p.push(k)}e+=g}M[d>>2]=e;return 0}};
(function(){function b(e){m.asm=e.exports;va=m.asm.M;Ga();Ha=m.asm.S;Ja.unshift(m.asm.N);Ma--;m.monitorRunDependencies&&m.monitorRunDependencies(Ma);0==Ma&&(null!==Na&&(clearInterval(Na),Na=null),Oa&&(e=Oa,Oa=null,e()))}function a(e){b(e.instance)}function c(e){return Sa().then(function(f){return WebAssembly.instantiate(f,d)}).then(function(f){return f}).then(e,function(f){qa("failed to asynchronously prepare wasm: "+f);ua(f)})}var d={a:sc};Ma++;m.monitorRunDependencies&&m.monitorRunDependencies(Ma);
if(m.instantiateWasm)try{return m.instantiateWasm(d,b)}catch(e){return qa("Module.instantiateWasm callback failed with error: "+e),!1}(function(){return ra||"function"!=typeof WebAssembly.instantiateStreaming||Pa()||N.startsWith("file://")||"function"!=typeof fetch?c(a):fetch(N,{credentials:"same-origin"}).then(function(e){return WebAssembly.instantiateStreaming(e,d).then(a,function(f){qa("wasm streaming compile failed: "+f);qa("falling back to ArrayBuffer instantiation");return c(a)})})})().catch(ba);
return{}})();m.___wasm_call_ctors=function(){return(m.___wasm_call_ctors=m.asm.N).apply(null,arguments)};var jb=m._free=function(){return(jb=m._free=m.asm.O).apply(null,arguments)},rc=m._malloc=function(){return(rc=m._malloc=m.asm.P).apply(null,arguments)},ib=m.___getTypeName=function(){return(ib=m.___getTypeName=m.asm.Q).apply(null,arguments)};m.__embind_initialize_bindings=function(){return(m.__embind_initialize_bindings=m.asm.R).apply(null,arguments)};
m.dynCall_jiji=function(){return(m.dynCall_jiji=m.asm.T).apply(null,arguments)};var tc;Oa=function uc(){tc||vc();tc||(Oa=uc)};
function vc(){function b(){if(!tc&&(tc=!0,m.calledRun=!0,!wa)){Ta(Ja);aa(m);if(m.onRuntimeInitialized)m.onRuntimeInitialized();if(m.postRun)for("function"==typeof m.postRun&&(m.postRun=[m.postRun]);m.postRun.length;){var a=m.postRun.shift();Ka.unshift(a)}Ta(Ka)}}if(!(0<Ma)){if(m.preRun)for("function"==typeof m.preRun&&(m.preRun=[m.preRun]);m.preRun.length;)La();Ta(Ia);0<Ma||(m.setStatus?(m.setStatus("Running..."),setTimeout(function(){setTimeout(function(){m.setStatus("")},1);b()},1)):b())}}
if(m.preInit)for("function"==typeof m.preInit&&(m.preInit=[m.preInit]);0<m.preInit.length;)m.preInit.pop()();vc();


  return Rive.ready
}
);
})();
export default Rive;